@font-face {
  font-family: "Avenir LT Pro";
  src: url("AvenirLT/AvenirLT-Medium.eot");
  src: url("AvenirLT/AvenirLT-Medium.eot?#iefix") format("embedded-opentype"),
    url("AvenirLT/AvenirLT-Medium.woff2") format("woff2"), url("AvenirLT/AvenirLT-Medium.woff") format("woff"),
    url("AvenirLT/AvenirLT-Medium.ttf") format("truetype"), url("AvenirLT/AvenirLT-Medium.svg#AvenirLT Medium") format("svg");
  ;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}