.my-rotating {
    height: 700px;
    overflow: hidden;
    padding: 0
}

.rotating-slider li[style] {
    transform-origin: center 775.692px 0px;
    width: 700px !important;
    height: 420px !important;
    padding: 0 15px !important;
    top: 36.2797px;
    clip-path: none !important;
}

.rotating-slider ul.slides li {
    color: #fff
}

/*.rotating-slider ul.slides li:nth-of-type(1) { background: #3498db; }

.rotating-slider ul.slides li:nth-of-type(2) { background: url('http://static.pexels.com/wp-content/uploads/2014/05/car-oldtimer-snow-342-825x550.jpg'); }

.rotating-slider ul.slides li:nth-of-type(3) { background: #e74c3c; }

.rotating-slider ul.slides li:nth-of-type(4) { background: url('http://static.pexels.com/wp-content/uploads/2014/06/analog-camera-old-olympus-om-10-1528-825x550.jpg'); }

.rotating-slider ul.slides li:nth-of-type(5) { background: #f1c40f; }

.rotating-slider ul.slides li:nth-of-type(6) { background: url(http://static.pexels.com/wp-content/uploads/2014/06/bridge-city-night-645-827x550.jpg); }*/

.rotating-slider ul.slides li .inner {
    box-sizing: border-box;
    padding: 2em;
    height: 100%;
    width: 100%;
}

.rotating-slider {
    margin: 5em auto;
    height: 360px;
    position: relative;
    width: 480px;
}

.rotating-slider ul.direction-controls {
    list-style: none;
    margin: 0;
    padding: 0;
}

.rotating-slider ul.direction-controls li.left-arrow {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
}

.rotating-slider ul.direction-controls li.right-arrow {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
}

.rotating-slider ul.direction-controls li button {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: bold;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0;
    text-align: center;
    transition: background 0.25s;
    width: 1.5em;
}

.rotating-slider ul.direction-controls li button:hover {
    background: rgba(0, 0, 0, 0.6);
}

.rotating-slider ul.slides {
    border-radius: 50%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(0);
    transform-origin: center center;
    width: 100%;
}

.rotating-slider ul.slides li {
    /*background-color: #ecf0f1;*/
    background-position: center;
    background-size: cover;
    display: block;
    list-style: none;
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    transform-origin: bottom center;
    width: 100%;
}

.inner {
    position: relative
}

.card {
    position: absolute;
    left: 0;
    right: 0;
    top: 130px
}

.card p {
    font-size: 1.1em;
    color: #162836;
    line-height: 1.3;
    padding: 30px 150px
}

.card-btn {
    text-align: center;
    padding: 10px 20px;
    line-height: 1.6;
    font-weight: 400;
    background: #162836;
    border: 1px solid#162836;
    color: #1accf4;
    font-size: 1em;
    border-radius: 10px;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
}

.card-btn:hover {
    background: #162836;
    border: 1px solid#162836;
    color: #1accf4;
}