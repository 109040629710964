nav.bg-light {
  background: #0e1b26 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;
  white-space: nowrap;
}

.nav-link {
  font-family: Avenir LT Pro;
  font-weight: 500;
  margin-right: 20%;
  line-height: normal;
  font-stretch: normal;
  font-size: 23px;
}
.banner {
  /* width: 100%;
  height: 87px; */
  flex-shrink: 0;
  background: linear-gradient(113deg, #00fefe 0%, #1b9dfe 100%), #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.banner-content {
  color: #000;
  font-size: 28px;
  font-family: "Avenir LT Pro";
  font-weight: 500;
}

.action .opacity {
  opacity: 0.1; /* Adjust the opacity value as per your preference */
}

@media screen and (max-width: 768px) {
  .banner {
    height: 60px;
  }

  .banner-content {
    font-size: 18px;
  }
}
.brochure {
  top: 12vh;
  opacity: 1;
  background: rgba(14, 27, 38, 0.9);
  position: absolute;
  z-index: 2;
}
.brocher-card {
  position: absolute;
  z-index: 5;
  width: 450px;
  height: 500px;
  top: 40vh;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0e1b26;
  color: #ffffff;
  padding: 25px 30px 50px 30px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
  border: 3px solid;
  border-image: linear-gradient(113deg, #00fefe 0%, #1b9dfe 100%);
  border-image-slice: 1;
  border-radius: 20px;
}

.card-header h2 {
  font-size: 35px;
  font-family: "Avenir LT Pro";
  font-weight: 500;
  margin-bottom: 50px;
}

.card-content {
  color: rgba(207, 207, 207, 0.9);
  font-size: 20px;
  font-family: "Avenir LT Pro";
  display: flex;
  justify-content: center;
  padding-right: 50px;
  flex-direction: column;
  gap: 30px;
}

.card-content .pdf {
  display: flex;
  justify-content: space-between;
}

.card-content .pdf img {
  padding: 0;
  cursor: pointer;
}

.button-download {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6vh;
}

.download-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 50px;
  border-radius: 12px;
  background: linear-gradient(113deg, #00fefe 0%, #1b9dfe 100%);
  color: #ffffff;
  font-family: "Avenir LT Pro";
  font-size: 30px;
  gap: 10px;
  cursor: pointer;
}
.overlay {
  display: none;
  opacity: 0.8;
  background-color: rgba(14, 27, 38, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.overlay.show {
  display: block;
}

.background-dimmed {
  opacity: 0.5;
  background-color: rgba(14, 27, 38, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media screen and (max-width: 768px) {
  .brocher-card {
    width: 300px;
    height: 400px;
  }
  .card-header h2 {
    font-size: 22px;
  }
  .card-content {
    font-size: 16px;
  }
  .download-button {
    font-size: 20px;
  }
}
