.banner {
  width: 100%;
  height: 87px;
  flex-shrink: 0;
  background: linear-gradient(113deg, #00fefe 0%, #1b9dfe 100%), #d9d9d9;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
}

.banner-content {
  color: #000;
  font-size: 28px;
  font-family: "Avenir LT Pro";
  font-weight: 500;
}
