.rightArrow {
  position: absolute;
  top: 50%;
  right: 20px;
  font-size: 2rem;
}

.leftArrow {
  position: absolute;
  top: 50%;
  left: 20px;
  font-size: 2rem;
}

.newButtons {
  display: flex;
  flex-direction: column;
}

.StyledSlider {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-inner > div {
  background-color: transparent !important;
}

/* new carousel */
.carousel__wrap {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.carousel__inner {
  height: 30rem;
  position: relative;
  width: calc(115rem);
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  transform: translateX(-50%);
  width: calc(270rem);
}

.carousel__slide-item {
  display: inline-block;
  height: 30rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 30rem;
}

.carousel__slide-item-img-link {
  /* cursor: zoom-in; */
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  border-radius: 10px;
}

.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}

.carousel__slide-item-img-link::after {
  align-items: center;
  /* background: rgba(0, 0, 0, 0.5); */
  color: white;
  /* content: 'read more'; */
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}

.carousel__slide-item-img-link:hover::after {
  opacity: 1;
}

.carousel__slide-item-img-link:hover img {
  transform: scale(1.3);
}

.carousel__slide-item-img-link:hover img.img-one,
.carousel__slide-item-img-link:hover img.img-two {
  transform: scale(1);
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}

.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}

.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}

p.we-combine {
  font-size: 24px;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel__btn--prev {
  left: -10rem;
}

.carousel__btn--next {
  right: -10rem;
}

.carousel__btn-arrow {
  border: solid white;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}

.carousel__btn-arrow--left {
  transform: rotate(135deg);
}

.carousel__btn-arrow--right {
  transform: rotate(-45deg);
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 0.5rem;
  position: absolute;
  transform: translateX(-50%);
}

.carousel__dots .dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  transform: scale(0.5);
  width: 2rem;
}

.carousel__dots .dot.active {
  background: #555;
}

.homepage.opacity {
  opacity: 0.1; /* Adjust the opacity value as desired */
  transition: opacity 0.3s ease-in-out; /* Add transition effect */
}
