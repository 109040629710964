html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  background: #0e1b26;
  font-family: "Avenir LT Pro";
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
li {
  font-family: "Avenir LT Pro", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
}

body a {
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.cl {
  clear: both;
}

.topnav {
  padding: 0 70px;
}

.text-center-desktop {
  text-align: center;
}

.navbar-default {
  background: transparent;
  border-radius: 10px;
  border-color: transparent;
  padding: 0 25px;
}

.navbar-brand {
  padding: 15px 0px;
}

.navbar-brand > img {
  display: block;
  width: 120px;
}

.navbar-default .navbar-nav > li > a {
  padding: 30px;
  color: #fff;
  font-weight: 600;
  font-size: 1.2em;
}

.mt-15 {
  margin-top: 15px;
}

.navbar-default .navbar-nav > li > a:hover {
  color: #05eafd;
}

.navbar-default .navbar-left {
  margin-left: 0;
}

.navbar-default .navbar-nav > li > a.act {
  color: #05eafd;
}

.action {
  text-align: center;
  padding: 8px 40px;
  line-height: 1.6;
  font-weight: 400;
  background-image: linear-gradient(to right, #00fbfd, #1b9dfe);
  border: 0px solid#b9a23f;
  color: #0e1b26;
  font-size: 1em;
  border-radius: 5px;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  margin-top: 20px;
  margin-left: 35px;
}

.action:hover {
  background-image: linear-gradient(to right, #1b9dfe, #00fbfd);
  border: 0px solid#b9a23f;
  color: #0e1b26;
}

.mt100 {
  padding-top: 100px;
}

.header {
  padding: 25px 100px 0;
  background: url(../public/images/rectangle.png) top 50px right no-repeat;
}

.header h1 {
  color: #fff;
  font-size: 4em;
  font-weight: 600;
  margin-bottom: 40px;
  line-height: 1.3;
}

.header span {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  color: #fff;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 40px;
}

.action2 {
  text-align: center;
  padding: 12px 40px;
  line-height: 1.6;
  font-weight: 400;
  background-image: linear-gradient(to right, #00fbfd, #1b9dfe);
  border: 0px solid#b9a23f;
  color: #0e1b26;
  font-size: 1em;
  border-radius: 5px;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  margin-top: 20px;
}

.action2:hover {
  background-image: linear-gradient(to right, #1b9dfe, #00fbfd);
  border: 0px solid#b9a23f;
  color: #0e1b26;
}

.home-pro {
  margin-top: 0px;
  padding: 0px 0 50px 100px;
}

.home-pro-in {
  border-top: 2px solid#05EAFD;
  border-left: 2px solid#05EAFD;
  border-bottom: 2px solid#05EAFD;
  border-radius: 15px 0 0 15px;
  padding: 35px;
}

.home-pro-in h2 {
  color: #fff;
  font-size: 4em;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.3;
  display: inline-block;
}

.pro-box-text {
  padding: 20px 0 30px 0;
  font-size: 3.8em;
}

.pro-box {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.pro-box img {
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.pro-box:hover img {
  transform: scale(1.3);
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.pro-hover {
  position: absolute;
  width: 100%;
  background: transparent;
  z-index: 9;
  top: 0;
  height: 100%;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.pro-hover h3.pro-box-text.hidden-mobile {
  color: #fff;
  font-size: 3.8em;
  font-weight: 500;
  line-height: 1.3;
  text-align: left;
  bottom: -70px;
  width: 100%;
  margin-left: 50px;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.bg-color {
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0px;
  padding: 30px 0;
}

.pro-box .bg-color.shown-mobile {
  background: rgba(5, 234, 253, 0.3);
}

.home-page .pro-hover h3.pro-box-text.hidden-mobile {
  bottom: -100px;
  position: absolute;
}

.pro-box:hover .bg-color.hidden-mobile {
  height: 100%;
  background: rgba(5, 234, 253, 0.3);
}

.home-page .pro-box:hover .pro-hover h3.pro-box-text.hidden-mobile {
  bottom: 20px;
}

.action3 {
  text-align: center;
  padding: 12px 40px;
  line-height: 1.6;
  font-weight: 400;
  background-image: linear-gradient(to right, #00fbfd, #1b9dfe);
  border: 0px solid#b9a23f;
  color: #0e1b26;
  font-size: 1em;
  border-radius: 5px;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.action3:hover {
  background-image: linear-gradient(to right, #1b9dfe, #00fbfd);
  border: 0px solid#b9a23f;
  color: #0e1b26;
}

.ino-solution {
  margin-top: 100px;
  padding: 0 100px;
  background: url(../public/images/rectangle2.png),
    url(../public/images/lines2.png);
  background-position: left top, right top 100px;
  background-repeat: no-repeat, no-repeat;
  height: 520px;
}

.ino-solution h3 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.ino-solution p {
  color: #fff;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 40px;
}

.ino-solution img {
  width: 400px;
  margin-top: 90px;
  margin-left: -150px;
}

.mt50 {
  margin-top: 50px;
}

.case {
  margin-top: 100px;
  padding: 0px 0 50px 100px;
}

.case-in {
  border-top: 2px solid#05EAFD;
  border-left: 2px solid#05EAFD;
  border-bottom: 2px solid#05EAFD;
  border-radius: 15px 0 0 15px;
  padding: 35px;
}

.case-in h4 {
  color: #fff;
  font-size: 4em;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 1.3;
  display: inline-block;
}

.case-box {
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.case-box img {
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.case-box:hover img {
  transform: scale(1.2);
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
}

.products-wrap {
  margin-top: 30px;
  padding: 0 100px;
}

.products-wrap h2 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: center;
}

.about-wrap {
  margin-top: 30px;
  padding: 0 100px;
  margin-top: -80px;
}

.about-wrap h2 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: center;
}

.about-wrap span {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.about-wrap h3 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 28%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 25px;
  margin-top: 100px;
}

.about-wrap .mt-100 {
  margin-top: 100px;
}

.values {
  background: #0b1820;
  padding: 35px;
  border-radius: 10px;
}

.values h4 {
  color: #fff;
  font-size: 2em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: center;
}

.values p {
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
}

.border-right {
  border-right: 2px solid#0d2536;
  height: 250px;
  padding-top: 25px;
}

.border-left {
  padding-top: 25px;
}

.about-wrap h5 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 25%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 25px;
  margin-top: 50px;
}

.fa-box {
  background: #152839;
  border-radius: 10px;
  padding-bottom: 20px;
  overflow: hidden;
}

.fa-box img {
  margin-bottom: 15px;
}

.fa-box strong {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #fff;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1;
  text-align: center;
}

.fa-box p strong {
  font-weight: 600;
}

.fa-box p {
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1;
  text-align: center;
}

.about-wrap h6 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 25px;
  margin-top: 50px;
}

.partner {
  margin-top: 30px;
  padding: 0 100px;
}

.partner h6 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 25px;
  margin-top: 50px;
}

.partner img {
  width: 120px;
  position: absolute;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}

@media (min-width: 768px) {
  .ten-columns > .col-sm-2 {
    width: 25%;
  }
}

.partner .col-sm-2 {
  min-height: 150px;
  padding-top: 6px;
  position: relative;
}

.contact-wrap {
  margin-top: 30px;
  padding: 0 100px;
}

.contact-wrap h2 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.contact-text2 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.contact-text1 {
  color: #fff;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 0px;
}

.contact-text1,
.contact-text2 {
  font-weight: bold;
}

.form .form-group {
  margin-bottom: 35px;
}

.form label {
  color: #fff;
}

.form .form-control {
  background: #122230;
  display: block;
  width: 100%;
  height: 55px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  background-image: none;
  border: 0px solid #ccc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.form #textarea {
  background: #122230;
  display: block;
  width: 100%;
  height: 110px;
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #fff;
  background-image: none;
  border: 0px solid #ccc;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s,
    box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
}

.form button img {
  width: 15px;
}

.contact-details {
  background: #122230;
  padding: 35px 35px 25px 35px;
  border-radius: 10px;
  margin-top: 150px;
}

.contact-details img {
  width: 40px;
  margin-top: -5px;
}

.contact-details p {
  color: #fff;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.3;
}

.innovative {
  padding: 25px 100px;
  background: #071a24;
  /*background: RGB(10, 24, 34) */
}

.innovative h1 {
  color: #fff;
  font-size: 4em;
  font-weight: 600;
  margin-bottom: 40px;
  line-height: 1.3;
}

.innovative span {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.innovative img {
  width: 400px;
}

.inno-video {
  /* background: #08151d; */
  background: #06151f;
  padding: 80px 100px;
}

.inno-video h2 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  margin-bottom: 40px;
  line-height: 1.3;
  text-align: center;
}

/* .domain {
  background-image: url(../public/images/lines1.png), url(../public/images/lines2.png);
  background-position: left top 50px, right top 40px;
  background-repeat: no-repeat, no-repeat;
  padding: 80px 100px;
} */
.domain {
  background-image: url(../public/images/lines1.png),
    url(../public/images/lines2.png);
  background-position: left 160px top 100px, right 100px top 130px;
  background-size: 300px, 270px;
  background-repeat: no-repeat, no-repeat;
  padding: 80px 100px;
}

.domain h3 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  margin-bottom: 70px;
  line-height: 1.3;
}

.domain p {
  color: #b2e1ee;
  font-size: 1.9em;
  font-weight: 300;
  line-height: 3;
}

.domain img {
  width: 70px;
}

.mt-50 {
  margin-top: 50px;
}

.domain .col-lg-4 {
  height: 200px;
}

.round-circle {
  background: #0b1620;
  padding: 150px 100px;
  margin-top: 100px;
  position: relative;
  overflow: hidden;
}

.c-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.c-img img {
  margin: auto;
  display: block;
  margin-top: -100px;
}

.mt-100 {
  margin-top: 100px;
}

.footer {
  background-image: linear-gradient(to right, #00fbfd, #1b9dfe);
  padding: 50px 100px 20px;
}

.footer p {
  color: #0c4751;
  font-size: 1.1em;
  font-weight: 400;
  line-height: 1.5;
}

.round-circle center.get-in-touch {
  position: absolute;
  z-index: 98;
  top: 25%;
  left: 42%;
}

.f-logo img {
  width: 280px;
}

.footer .list ul {
  list-style: none;
  margin-left: -40px;
}

.footer .list li {
  color: #0c4751;
  font-size: 1em;
  /* font-weight: 600; */
  margin: 10px 0;
}

.btn {
  font-weight: 600;
}

.footer .list a {
  color: #0c4751;
  text-decoration: none;
}

.footer .list a:hover {
  color: #000;
  text-decoration: none;
}

.ftline {
  border-top: 2px solid#0dbde8;
  width: 100%;
  margin-top: 70px;
}

.footer .inline-social ul {
  list-style: none;
  text-align: right;
}

.footer .inline-social li {
  display: inline-block;
  margin-right: 25px;
}

.footer .inline-social li a {
  color: #0c4751;
  font-size: 1.5em;
}

.footer .inline-social li a:hover {
  color: #000;
}

.footer .inline-link ul {
  list-style: none;
  margin-left: -40px;
}

.footer .inline-link li {
  display: inline-block;
  color: #0c4751;
  margin-right: 25px;
  font-size: 1em;
}

.footer .inline-link a {
  color: #0c4751;
  text-decoration: none;
}

.footer .inline-link a:hover {
  color: #000;
  text-decoration: none;
}

.top-scroll {
  display: none;
  position: fixed;
  bottom: 50px;
  right: 4%;
  z-index: 1000;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 35px;
  font-size: 23px;
  color: #000;
  text-align: center;
  background: #fff;
  border-radius: 25px;
  cursor: pointer;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  transform: translateZ(0);
  opacity: 1;
}

.top-scroll:hover {
  opacity: 1;
}

#mobile {
  display: none;
}

.wrap-404 {
  margin-top: 50px;
  padding: 100px 100px;
  background: url(../public/images/round-circle.png) right -30% top -100px
    no-repeat;
}

.wrap-404 h2 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.wrap-404 img {
  margin-bottom: 40px;
}

.accord {
  margin-top: 100px;
  padding: 0 100px;
  background: url(../public/images/rectangle2.png) top left no-repeat;
  height: 520px;
}

.accord h3 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 20px;
}

.accord p {
  color: #fff;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 40px;
}

.accord img {
  width: 400px;
  margin-top: -20px;
  margin-left: -150px;
}

.acco-img {
  margin-top: 100px;
  padding: 0;
}

.acco-img .col-lg-4 {
  padding: 0;
}

.acco-slide {
  padding: 0;
  background: #0a1921;
}

.acco-slide .carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 300px;
  left: 10%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 70%;
  top: 250px;
}

.acco-slide .carousel-caption p {
  bottom: 250px;
  font-size: 3em;
  line-height: 45px;
  font-weight: 600;
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  text-align: left;
  margin-bottom: 50px;
}

.acco-slide .carousel-caption h3 {
  font-size: 1.2em;
  color: #b2e1ee;
  text-align: left;
  font-weight: 400;
  line-height: 1.2;
}

.acco-slide .carousel-control.right {
  background-image: none;
}

.acco-slide .carousel-control.left {
  background-image: none;
}

.acco-slide .carousel-indicators .active {
  height: 13px;
  background: #00fefe;
  border: 1px solid#00FEFE;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  width: 13px;
}

.acco-slide .carousel-indicators li {
  border: 1px solid#152C3F;
  background: #152c3f;
  -webkit-transition: 0.5s all;
  -moz-transition: 0.5s all;
  -o-transition: 0.5s all;
  transition: 0.5s all;
  display: block;
  margin: 8px 0;
  width: 13px;
  height: 13px;
}

.acco-slide .carousel-indicators {
  margin-left: -5%;
  left: 100%;
  bottom: 35%;
  width: 18px;
}

.acco-slide .carousel-inner .item img {
  width: 100%;
}

.workflow {
  background: #0b1620;
  padding: 70px 100px;
}

.workflow h3 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 50px;
}

.arrow-position {
  position: relative;
  padding-top: 140px;
}

.row-position {
  position: absolute;
  z-index: 9;
  top: 0;
  width: 100%;
}

.row-position .col-lg-4 {
  padding: 0 80px;
}

.arrow-box {
  background: #102433;
  height: 170px;
  width: 230px;
  border-radius: 10px;
  padding: 25px;
}

.arrow-box img {
  height: 60px;
  margin-bottom: 10px;
}

.arrow-box p {
  color: #b2e1ee;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
}

.mt-60 {
  margin-top: 60px;
}

.mt-25 {
  margin-top: 25px;
}

.border-radius20 {
  border-radius: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.round-arrow {
}

.arrow-box {
  background: #102433;
  height: 170px;
  width: 230px;
  border-radius: 10px;
  padding: 25px;
}

.arrow-box img {
  height: 60px;
  margin-bottom: 10px;
}

.arrow-box p {
  color: #b2e1ee;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.3;
  text-align: center;
}

.features {
  margin-top: 100px;
  padding: 0 100px 25px;
  background-image: url(../public/images/lines1.png),
    url(../public/images/lines2.png);
  background-position: left top 50px, right top 130px;
  background-repeat: no-repeat, no-repeat;
}

.features h4 {
  color: #fff;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.3;
  margin-bottom: 50px;
}

.features .col-lg-4 {
  margin-bottom: 50px;
}

.feature-box {
  background: #162836;
  padding: 15px 25px;
  border-radius: 10px;
}

.feature-box img {
  height: 50px;
  margin-right: 15px;
}

.feature-box span {
  color: #b2e1ee;
  font-size: 1.3em;
  font-weight: 300;
  line-height: 1.3;
}

#upbt {
  z-index: 999;
  position: relative;
}

#mobile-bt {
  display: none;
}

.col-md-push-7 {
  left: 40%;
  z-index: 1;
}

.col-md-pull-5 {
  right: 50%;
}

.about-page .about-carousel {
  display: none;
}

.about-page .my-rotating {
  display: block;
}

.shown-mobile {
  display: none;
}

.contact-wrap .col-lg-6 .form {
  width: 90%;
}

.terms p {
  color: rgb(178, 225, 238);
  font-size: 18px;
}

@media screen and (max-width: 600px) {
  .accord-wrap .round-circle {
    margin-top: 30px;
  }

  .innovative-wrap .round-circle {
    margin-top: 50px;
  }

  .acco-slide .carousel-caption p {
    font-size: 2em;
    margin: 30px 0;
  }

  .products-wrap h2 {
    margin-bottom: 50px;
  }

  .products-wrap h2,
  .accord h3 {
    font-size: 3em;
  }

  .accord .col-md-push-7 h3 {
    margin-bottom: 30px;
  }

  .accord .col-md-push-7 .action2 {
    margin-top: 35px;
  }

  .contact-text1 {
    font-size: 1.8em;
    font-weight: normal;
  }

  .contact-text2 {
    font-size: 2.5em;
  }

  .inline-social {
    padding: 10px 0 0 0;
  }

  .header p.we-combine {
    margin: 30px 0;
  }

  .navbar .nav.navbar-nav.navbar-right {
    text-align: center;
  }

  .mt-15 {
    margin-top: 0px;
  }

  .pro-box-text {
    padding: 20px 0 30px 0;
    font-size: 2em;
  }

  .workflow {
    padding: 50px 15px;
  }

  .text-center-desktop {
    text-align: inherit;
  }

  .footer .shown-mobile .inline-social li {
    margin-right: 35px;
  }

  .workflow h3 {
    color: #fff;
    font-size: 2em;
    font-weight: 600;
    line-height: 1.3;
    margin-bottom: 50px;
  }

  .round-arrow {
    display: none;
  }

  .row-position {
    position: relative;
  }

  .row-position .col-lg-4 {
    margin-bottom: 30px;
  }

  .mt-60 {
    margin-top: 0;
  }

  .mt-25 {
    margin-top: 0;
  }

  .arrow-position {
    padding-top: 0px;
  }

  .col-md-push-7 {
    left: 0%;
  }

  .col-md-pull-5 {
    right: 0%;
  }

  .contact-wrap .col-lg-6 .form {
    width: 100%;
  }

  .round-circle center.get-in-touch {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .home-page .carousel-inner {
    border-radius: 15px;
    margin-bottom: 40px;
  }

  .about-page .about-carousel {
    display: block;
  }

  .about-page .my-rotating {
    display: none;
  }

  img.img-two {
    position: absolute;
  }

  img.img-two.card-icon {
    width: 5rem;
    height: 3rem;
    top: 10%;
    right: 42%;
  }

  img.img-two.slide1sm {
    width: 50px;
    top: 16px;
    height: 125px;
    right: 42%;
  }

  img.img-two.slide2sm {
    width: 60px;
    top: 16px;
    height: 125px;
    right: 40%;
  }

  .img-desc-about {
    position: absolute;
    top: 10%;
    color: #162836;
    padding: 42px;
    text-align: center;
  }

  .about-page .carousel__slide-item-img-link img {
    object-fit: initial;
  }

  .about-page .carousel__slide-item {
    width: 35rem;
    height: 23rem;
  }

  .about-page .carousel__wrap {
    width: 100%;
  }

  .about-page .btn.card-btn {
    position: absolute;
    bottom: 0px;
    right: 30%;
  }

  .topnav {
    padding: 0 15px;
  }

  .domain p {
    text-align: center;
  }

  .domain img {
    margin: auto;
    display: block;
  }

  .hidden-mobile {
    display: none !important;
  }

  .shown-mobile {
    display: block;
  }

  p.we-combine {
    font-size: 18px !important;
  }

  .navbar-brand > img {
    width: 100px;
  }

  .navbar-default {
    padding: 0;
  }

  .navbar-default .navbar-left {
    margin-left: 0;
  }

  .navbar-toggle {
    margin-right: 0px;
    margin-top: 20px;
  }

  .navbar-default .navbar-nav > li > a {
    padding: 10px 0;
  }

  .action {
    margin-left: 0;
  }

  .mt100 {
    padding-top: 0;
  }

  .header {
    padding: 0 15px;
    background: url(../public/images/rectangle.png) top 250px right -50px no-repeat;
    height: 850px;
  }

  .header h1 {
    font-size: 2.5em;
    margin-bottom: 15px;
    font-weight: 600;
  }

  .header p {
    margin-bottom: 0;
    text-shadow: 1px 1px 1px #000;
  }

  .header img {
    height: 500px !important;
    margin-top: 50px;
  }

  .home-page .home-pro {
    padding: 0 15px;
  }

  .home-pro {
    padding: 0 0px 0 15px;
  }

  .home-pro-in {
    border: 2px solid #05eafd;
    border-radius: 15px;
    padding: 15px;
  }

  .home-pro-in h2 {
    font-size: 2em;
  }

  .home-pro-in .col-lg-4 {
    margin-bottom: 30px;
  }

  .ino-solution {
    padding: 0 15px;
    height: 728px;
    margin-top: 30px;
    background: url(../public/images/rectangle2.png) top 200px left -150px no-repeat;
  }

  .ino-solution img {
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: 150px;
  }

  .ino-solution h3 {
    font-size: 2em;
    margin-bottom: 10px;
  }

  .ino-solution .action2 {
    float: right;
  }

  .mt50 {
    margin-top: 25px;
  }

  .ino-solution p {
    margin-bottom: 0;
  }

  .case {
    margin-top: 30px;
    padding: 0 15px;
  }

  .case .pull-right {
    display: none;
  }

  .case-in {
    border: 2px solid #05eafd;
    border-radius: 15px;
    padding: 15px;
  }

  .case-in h4 {
    font-size: 2em;
  }

  .mt-100 {
    margin-top: 50px;
  }

  .footer {
    padding: 25px;
  }

  .f-logo img {
    width: 250px;
  }

  #desktop {
    display: none;
  }

  #mobile {
    display: block;
  }

  #mobile-bt {
    display: inline-block;
  }

  #mobile .card {
    top: 30px;
  }

  #mobile .card img {
    width: 50px !important;
    margin: auto;
    display: block;
  }

  #mobile .owl-carousel .owl-item img {
    width: 100%;
  }

  #mobile .owl-carousel .owl-item img {
    display: block;
    width: 61%;
  }

  .card p {
    font-size: 1em;
    color: #162836;
    line-height: 1.3;
    padding: 20px;
    text-align: center;
  }

  .my-rotating {
    height: auto;
  }

  .about-wrap {
    padding: 0 15px;
    margin-top: 20px;
  }

  .about-wrap h2 {
    font-size: 2em;
  }

  .about-wrap h3 {
    font-size: 2em;
    margin-top: 50px;
  }

  .border-right {
    border-right: 0;
  }

  .border-left {
    padding-top: 80px;
  }

  .about-wrap h5 {
    font-size: 2em;
  }

  .about-wrap .col-lg-3 {
    margin-bottom: 30px;
  }

  .about-wrap h6 {
    font-size: 2em;
  }

  .partner h6 {
    font-size: 2em;
    margin-bottom: 0px;
  }

  .partner .col-sm-2 {
    min-height: 127px;
  }

  .products-wrap {
    padding: 0 15px;
  }

  /* .products-wrap h2 {
    font-size: 2em;
  } */

  .products-wrap .col-lg-4 {
    margin-bottom: 30px;
  }

  .innovative {
    padding: 0px 15px;
  }

  .innovative h1 {
    font-size: 2em;
  }

  .innovative img {
    width: 300px;
    margin: auto;
    display: block;
  }

  .inno-video {
    padding: 50px 15px;
  }

  .inno-video h2 {
    font-size: 2em;
  }

  .domain {
    padding: 50px 15px;
    background: none;
  }

  .domain h3 {
    font-size: 2em;
    text-align: center;
  }

  .domain p {
    text-align: center;
  }

  .domain img {
    margin: auto;
    display: block;
  }

  .c-img img {
    width: 100%;
    margin-top: -22px;
  }

  .round-circle {
    padding: 50px 15px;
    min-height: 150px;
  }

  .contact-wrap {
    padding: 0 15px;
  }

  .contact-wrap h2 {
    font-size: 2em;
  }

  .form {
    margin-top: 50px;
  }

  .contact-details .col-lg-1 {
    margin-bottom: 10px;
  }

  .contact-details .col-lg-3 {
    margin-bottom: 30px;
  }

  .wrap-404 {
    background: none;
    padding: 50px 15px;
  }

  .wrap-404 h2 {
    font-size: 2em;
    text-align: center;
  }

  .wrap-404 img {
    margin-bottom: 10px;
    margin: auto;
    display: block;
  }

  .pro-hover h3 {
    font-size: 2.5em;
    margin-left: 25px;
    /* text-shadow: 1px 1px 1px #000 */
  }

  .domain p {
    font-size: 1.3em;
    line-height: 1.5;
    margin-top: 8px;
  }

  .domain .col-lg-4 {
    height: 150px;
  }

  .domain img {
    height: 50px;
    width: 50px;
  }

  .partner {
    padding: 0 15px;
  }

  .partner img {
    width: 72px;
    top: 30px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .contact-details .col-lg-4 {
    height: 80px;
  }

  .accord {
    padding: 0 15px;
    margin-top: 0;
    background: url(../public/images/rectangle2.png) top 300px left -100px no-repeat;
    height: 850px;
  }

  .accord p {
    margin-bottom: 0;
  }

  .accord .action2 {
    float: right;
  }

  .col-md-push-7 {
    left: 0%;
  }

  .accord img {
    width: 400px;
    margin-top: 20px;
    margin-left: -50px;
  }

  .acco-img {
    margin-top: 50px;
  }

  .acco-slide {
    height: 550px;
    margin-top: 50px;
  }

  .acco-slide .carousel-caption {
    position: relative;
    right: 15%;
    bottom: 0;
    left: 5%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    top: -11px;
  }

  .features {
    margin-top: 50px;
    padding: 0 15px 0px;
    background-image: none;
  }

  .acco-slide .carousel-caption p {
    line-height: 1;
  }

  .feature-box {
    padding: 10px;
    min-height: 51px;
  }

  .feature-box span {
    font-size: 0.8em;
  }

  .feature-box img {
    height: 30px;
    width: 40px;
    margin-right: 10px;
  }
}

.invalid-feedback {
  color: darkred;
}

.m-l-btn {
  margin-right: 75px;
}

@keyframes nueral-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes nueral-logo-spin-anti {
  from {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(0deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .nueral-logo {
    animation: nueral-logo-spin 5s linear;
  }

  .nueral-logo-anti {
    animation: nueral-logo-spin-anti 5s linear;
  }
}

.navbar-collapse {
  box-shadow: inset 0 0px 0 rgb(255 255 255 / 10%);
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #0e1b2600 !important;
}

.gmap_canvas {
  border-radius: 10px;
}
.mapouter {
  position: relative;
  text-align: right;
  width: 100%;
  height: 400px;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  width: 100%;
  height: 400px;
}
.gmap_iframe {
  height: 400px !important;
}

.inf h3 {
  background: -webkit-linear-gradient(45deg, #00fbfd, #1b9dfe 90%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 3em;
  font-weight: 600;
  line-height: 1.3;
  margin-top: 25%;
  margin-left: 5%;
}

.inf p {
  color: #fff;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 40px;
  margin-left: 5%;
}

.carousel-indicators li {
  margin: 5px !important;
}
.carousel-indicators .active {
  margin: 5px !important;
}

@media only screen and (min-width: 768px) {
  .text_desktop {
    top: 140px !important;
  }
}

.homepage.opacity {
  opacity: 0.1;
  transition: opacity 0.3s ease-in-out;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9 {
  float: left;
}

/* @media (min-width: 1541px){
  .col-lg-4:nth-child(7) {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
} */
